import React from 'react'
import { StopOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

export const getStatusTagColor = (tag: string) => {
  switch (tag) {
    case 'cancelled':
      return 'magenta'
    case 'completed':
      return 'green'
    case 'error':
      return 'red'
    case 'failed':
      return 'volcano'
    case 'qc':
      return 'cyan'
    case 'pending':
      return 'gold'
    default:
      return 'geekblue'
  }
}

export const getTypeTagColor = (tag: string) => {
  switch (tag) {
    case 'wgs':
      return 'magenta'
    case 'wes':
      return 'purple'
    case 'tgs':
      return 'green'
    default:
      return 'cyan'
  }
}

export const getOrderTagColor = (tag: string) => {
  switch (tag) {
    case 'total':
      return 'blue'
    case 'running':
      return 'cyan'
    case 'delivered':
      return 'green'
    case 'completed':
      return 'lime'
    case 'failed':
      return 'volcano'
    case 'cancelled':
      return 'magenta'
    case 'sequencing':
      return 'purple'
    case 'lab prep':
      return 'gold'
    case 'not received':
      return 'pink'
    default:
      return 'orange'
  }
}

export const getWorkflowTagColor = (tag: string) => {
  switch (tag?.toLowerCase()) {
    case 'balsamic':
      return '#16a596'
    case 'mip-dna':
      return '#bc6ff1'
    case 'mip-rna':
      return '#800000'
    case 'fluffy':
      return '#e6739f'
    case 'microsalt':
      return '#eb7047'
    case 'mutant':
      return '#44aeeb'
    case 'demultiplex':
      return '#096194'
    case 'rsync':
      return '#56c46c'
    case 'rnafusion':
      return '#b9d611'
    case 'taxprofiler':
      return '#6a432d'
    case 'fastq':
      return '#0b5531'
    case 'tomte':
      return '#ff0000'
    case 'raredisease':
      return '#0000ff'
    default:
      return '#737373'
  }
}

export const getPriorityTagColor = (tag: string) => {
  switch (tag) {
    case 'high':
      return 'gold'
    case 'normal':
      return 'cyan'
    case 'express':
      return 'volcano'
    default:
      return 'blue'
  }
}

export const antdPresets: string[] = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'bluegeek',
  'bluepurple',
]

// Returns an Antd preset color given any string
export const colorFromString = (str: string, colorList = antdPresets) => {
  const numberFromString = parseInt(String(str.length).charAt(0))
  return colorList[numberFromString]
}

export const AtlasTagColors = {
  expired: 'volcano',
  upToDate: 'green',
}

export const sexTags = {
  male: 'purple',
  female: 'magenta',
  unknown: 'cyan',
}

export const notAvailableTag = {
  null: 'gold',
}

export const genotypeSampleStatus = {
  fail: 'red',
  cancel: 'gold',
  pass: 'green',
}

export const genotypeSampleStats = {
  matches: 'green',
  mismatches: 'red',
  unknown: 'cyan',
}

export const genotypeSampleStatusDetail = {
  fail: <CloseCircleOutlined />,
  cancel: <StopOutlined />,
  pass: <CheckCircleOutlined />,
}
