import React, { useState, useEffect } from 'react'
import { Card, Flex, Table } from 'antd'
import { editOrder, getOrders } from '../services'
import styles from './EditableTable.module.css'
import { OrdersTableColumns } from './Columns/OrdersTableColumns'
import SearchBar from './Search/SearchBar'
import SaveScrollPosition from '../../Common/SaveScrollPosition'
import { createNotification } from '../../Common/services/helpers/helpers'

export const EditableOrdersTable = ({ workflow, refresh }) => {
  const [dataSource, setDataSource] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState<any>({})
  const [sorter, setSorter] = useState<any>({})
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(0)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  })

  useEffect(() => {
    fetchData()
  }, [refresh, search, pagination, filters, sorter])

  const fetchData = async () => {
    setIsLoading(true)
    const response = await getOrders(workflow, search, pagination, filters, sorter)
    setTotal(response.total_count)
    setDataSource(response.orders)
    setIsLoading(false)
  }

  const handleTableChange = (newPagination, newFilters, newSorter) => {
    setFilters(newFilters)
    setSorter(newSorter)
    setPagination(newPagination)
  }
  const searchData = (value) => setSearch(value)

  const updateItem = (item, id, response) => {
    if (item.id === id) {
      return {
        ...item,
        is_open: response.is_open,
      }
    }

    return item
  }

  const handleOrderOpening = (id, open: boolean) => {
    editOrder(undefined, id, open).then((response) => {
      const data = [...dataSource]
      const newData = data.map((item) => updateItem(item, id, response))
      setDataSource(newData)
      createNotification('success', 'Order status updated successfully')
    })
  }

  const columns = OrdersTableColumns(handleOrderOpening)

  return (
    <Flex gap="middle" vertical>
      {!isLoading && <SaveScrollPosition />}
      <Card style={{ position: 'sticky', top: 103, zIndex: 6 }}>
        <SearchBar searchData={searchData} />
      </Card>
      <Table
        className={styles.table}
        bordered
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        pagination={{ ...pagination, total }}
        loading={isLoading}
        onChange={handleTableChange}
        sticky={{
          offsetHeader: 183,
        }}
        rowClassName={(record: { is_open: boolean }) => {
          return !record.is_open ? `${styles.customRow} ${styles.signed}` : ''
        }}
      />
    </Flex>
  )
}
