import { Notification } from '../interfaces'
import { notification } from 'antd'
import moment from 'moment'
import { AxiosError } from 'axios'
import { store } from '../../../../domain/store'

export const OpenNotification = ({ type, message, description }: Notification) => {
  const key = `open${Date.now()}`
  notification[type]({
    message,
    description,
    btn: null,
    key,
    duration: 3,
  })
}

export const createErrorNotification = (error: AxiosError) => {
  const { errorMessage, errorDescription } = createErrorMessage(error)
  OpenNotification({
    type: 'error',
    message: errorMessage,
    description: errorDescription,
  })
}

export const createNotification = (type, message, description?) => {
  OpenNotification({
    type: type,
    message: message,
    description: description,
  })
}

export const createErrorMessage = (error: AxiosError) => {
  switch (error?.response?.status) {
    case 400:
      return {
        errorMessage: 'Not found',
        errorDescription: `${error.response?.data.error}`,
      }
    case 401:
      return {
        errorMessage: `${error?.message}`,
        errorDescription: `Unauthorized on ${error?.config?.url}`,
      }
    case 403:
      return {
        errorMessage: `${error?.message}`,
        errorDescription: `You don't have permissions to access the data from ${error?.config?.url}`,
      }
    case 404:
      return {
        errorMessage: 'Not found',
        errorDescription: `${error?.message} ${error?.config?.url}`,
      }
    case 406:
      return {
        errorMessage: 'Not possible to execute this action',
        errorDescription: `${error?.message} ${error?.config?.url}`,
      }
    case 409:
      return {
        errorMessage: `${error?.message}`,
        errorDescription: `The request could not be completed due to a conflict with the current state of the target resource.`,
      }
    case 412:
      return {
        errorMessage: 'Error',
        errorDescription: `${error?.response?.data?.error}`,
      }
    case 500:
      return {
        errorMessage: 'Something went wrong in the backend',
        errorDescription: `${error?.message} ${error?.config?.url}`,
      }
    default:
      return {
        errorMessage: `Could not fetch data from backend, make sure you are connected to the VPN`,
        errorDescription: `${error?.message} ${error?.config?.url}`,
      }
  }
}

export const formatDate = (date: string) => (date ? moment(date).format('YYYY-MM-DD') : null)

export const sortDate = (dateA: string, dateB: string) => {
  return new Date(dateA).getTime() - new Date(dateB).getTime()
}

export const formatReads = (reads: string, targetReads: string) => {
  return Math.floor((parseInt(reads, 10) * 100) / parseInt(targetReads, 10))
}

export const sortIssuesByLevel = (issueA, issueB): number => {
  let labelA = ''
  let labelB = ''

  issueA.labels.forEach((label) => {
    if (label.name.length === 1) labelA = label.name
  })
  issueB.labels.forEach((label) => {
    if (label.name.length === 1) labelB = label.name
  })
  return labelA.localeCompare(labelB)
}

export const windowMatchMedia = () => {
  return (
    window.matchMedia ||
    function () {
      return {
        matches: false,
        addListener: () => null,
        removeListener: () => null,
      }
    }
  )
}

const getToken = () => {
  const token = store?.getState()?.settings?.googleToken
  return token ? token : ''
}

export const axiosCall = (axiosMethod, token = getToken()) => {
  return new Promise((resolve, reject) => {
    axiosMethod({ headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
        createErrorNotification(error)
      })
  })
}

export const cleanTitle = (title) => {
  const removeUnderScore = title.replace(/[_-]/g, ' ').replace('count', '').replace('ui', '')
  const capitalize = removeUnderScore.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  )
  return capitalize
}

export const setTapTitleFromPath = (path) => {
  const parts = path.split('/').filter((part) => part !== '')

  if (parts.length === 0) {
    document.title = 'CIGRID'
    return
  }

  if ((parts.length >= 3 && parts[0] === 'analyses') || ('orders' && /^\d+$/.test(parts[1]))) {
    document.title = 'CIGRID'
    return
  }

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const title = parts.map((part) => capitalizeFirstLetter(part.replace('-', ' '))).join(': ')

  document.title = title
}
