import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const SaveScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(() => {
    const savedScrollPosition = localStorage.getItem('scrollPosition')
    return savedScrollPosition ? parseInt(savedScrollPosition) : window.scrollY
  })

  const location = useLocation()

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem('scrollPosition')
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition))
    }

    const handleScroll = () => {
      const currentPosition = window.scrollY
      setScrollPosition(currentPosition)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('scrollPosition', scrollPosition.toString())
  }, [location.pathname, scrollPosition])

  return <div>{/* Scrollable content */}</div>
}

export default SaveScrollPosition
