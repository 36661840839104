import React from 'react'
import { Tag, Row, Flex } from 'antd'
import { getWorkflowTagColor } from '../../../Common/styleHelpers'
import { ordersRootURL } from '../../../Common/constants'
import { AnalysisStatus } from '../Component/AnalysisStatus'
import { susyTicketLink } from '../../constants'
import { OrderOpeningAction } from '../../Order/OrderSummary/OrderOpeningAction'
import { OrderDeliveryMessageBtn } from '../../Order/OrderSummary/OrderDeliveryMessageBtn'
import { performBulkAction } from '../BulkActions/utils'
import { createNotification } from '../../../Common/services/helpers/helpers'
import { Link } from 'react-router-dom'

const ordersColumns = (handleOrderOpening) => {
  const handleAnalysesDelivery = async (analysisIds) => {
    if (analysisIds.length) {
      await performBulkAction('delivered', true, analysisIds, null)
      createNotification('success', 'Analyses marked as delivered')
    }
  }

  return [
    {
      title: 'Order',
      key: 'id',
      dataIndex: 'id',
      render: (order_id) => <Link to={`${ordersRootURL}/order/${order_id}`}>{order_id}</Link>,
      sorter: true,
    },
    {
      title: 'Ticket',
      key: 'ticket_id',
      dataIndex: 'ticket_id',
      width: 90,
      render: (ticket) => <a href={`${susyTicketLink}${ticket}`}>{ticket}</a>,
      sorter: false,
    },
    {
      title: 'Customer',
      dataIndex: 'customer_id',
      key: 'customer_id',
      render: (customer_id) => customer_id,
      sorter: false,
    },
    {
      title: 'Workflow',
      dataIndex: 'workflow',
      key: 'workflow',
      render: (workflow: any) =>
        workflow && (
          <Tag color={getWorkflowTagColor(workflow)} key={workflow}>
            {workflow.toUpperCase()}
          </Tag>
        ),
    },
    {
      title: 'Analyses',
      dataIndex: 'summary',
      key: 'summary',
      width: 600,
      render: (stats) => {
        return (
          <Row>
            <AnalysisStatus status={stats} />
          </Row>
        )
      },
    },
    {
      title: 'Order date',
      dataIndex: 'order_date',
      key: 'order_date',
      sorter: (a, b) => a.order_date.localeCompare(b.order_date),
    },
    {
      title: 'Status',
      dataIndex: 'is_open',
      key: 'is_open',
      render: (is_open) => {
        return !is_open ? <Tag color="green">CLOSED</Tag> : <Tag color="yellow">OPEN</Tag>
      },
      filters: [
        {
          text: 'Open',
          value: true,
        },
        {
          text: 'Closed',
          value: false,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Action',
      key: 'action',
      ellipsis: true,
      width: 100,
      render: (record) => {
        return (
          <>
            <Flex justify="space-between">
              <OrderOpeningAction order={record} handleOpening={handleOrderOpening} />
              <OrderDeliveryMessageBtn order={record} handleDelivery={handleAnalysesDelivery} />
            </Flex>
          </>
        )
      },
    },
  ]
}

export const OrdersTableColumns = (handleOpening) => {
  const defaultColumns: any[] = ordersColumns(handleOpening)
  return defaultColumns
}
